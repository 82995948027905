import React, { Suspense, useEffect } from "react";
import Main from "components/Main";
import Private from "Routes/Private";
import Public from "Routes/Public";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthenticationStatus,
  getAuthenticationToken,
} from "store/selectors";
import { loginWithToken, logout } from "store/actions/login";
import LoadingBar from "components/LoadingBar";
import { useLocation } from "react-router";
import { appUrl } from "configs/parameters";

const Routes = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const authenticated = useSelector(getAuthenticationStatus);
  const authToken = useSelector(getAuthenticationToken);

  if (pathname === "/logout") {
    dispatch(logout());
  }

  useEffect(() => {
    if (!authenticated) {
      const queryParams = new URLSearchParams(search);
      const token = queryParams.get("token");
      dispatch(loginWithToken(token));
    } else if (authToken) {
      axios.defaults.headers.common["Authorization"] = "JWT " + authToken;
    }
  }, [authenticated, search, dispatch]);

  useEffect(() => {
    if (authenticated === false) {
      window.location.replace(`${appUrl}/logout`);
    }
  }, [authenticated]);

  return (
    <Suspense fallback={<LoadingBar />}>
      {true === authenticated && (
        <Main>
          <Suspense fallback={<LoadingBar/>}>
            <Private />
          </Suspense>
        </Main>
      )}
      {authenticated === false && <Public />}
    </Suspense>
  );
};

export default Routes;
