import React, { Suspense } from 'react';
import LoadingBar from '../LoadingBar';
import Sidebar from './Sidebar';
// import UserMenu from './UserMenu';
import Logout from './Logout';
import './index.css';

const Main = ({ children }) => (
  <div className="main-container">
    <Sidebar />
    <div className="page-content">
      {/* <UserMenu /> */}
      <Logout />
      <div className="container p-4">
        <LoadingBar />
        <Suspense fallback={<LoadingBar />}>
          {children}
        </Suspense>
      </div>
    </div>
  </div>
);

export default Main;
